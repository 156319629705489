import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { ErrorCodes, JrpcResponse } from '@httpClient/jrpc';
import { Profile, ProfileInterface } from '@core/entities/Opencity/Profile';
import { Flat, FlatInterface } from '@core/entities/Opencity/Flat';
import { FlatAccount, FlatAccountInterface } from '@core/entities/Opencity/FlatAccount';
import { User, UserInterfacee } from '@core/entities/Opencity/User';
import {
  User as BillingUser,
  UserInterface as BillingUserInterface,
  CreateUser,
  UpdateProps,
  DeleteProps,
} from '@core/entities/Billing/User';
import { Account } from '@core/entities/Billing/Account';
import { Loading, endLoading } from './interfaces/Loading';
import Store from './Store';
import AuthenticationStore from './AuthenticationStore';
import ProfileStore from './ProfileStore';
import SnackbarStore from './SnackbarStore';
import UserStore from './UserStore';
import BillingOrganizationStore from './BillingOrganizationStore';
// import { isBilling } from '@constants/project';
import AccountStore from './AcccountStore';
import AccrualStore from './AccrualStore';
import PreferenceStore from './PreferenceStore';
import SmevStore from './SmevStore';
import DadataStore from './DadataStore';
import { DEFAULT_USER_PREFERENCE, UserPreference } from '@core/entities/Opencity/Preference';
import OperationStore from './OperationStore';
import BillingMethods from '@services/billing/methods';
import OpencityMethods from '@services/opencity/methods';
import {
  Contract,
  ContractFilterProps,
  ContractInterface,
  ContractResponseType,
} from '@core/entities/Billing/Contract';
import { parseFlatNumber } from '@core/utils/parseFlatNumber';

type ProfileIndexResponse = JrpcResponse<{ items: ProfileInterface[] }>;
type UserIndexResponse = JrpcResponse<{ items: UserInterfacee[] }>;
type UserCreateResponse = JrpcResponse<BillingUser>;
type UserUpdateResponse = JrpcResponse<BillingUser>;
type UserDeleteResponse = JrpcResponse<BillingUser>;
type BillingUserIndexResponse = JrpcResponse<{ items: BillingUserInterface[] }>;
type GisFlatIndexResponse = JrpcResponse<{ items: FlatInterface[] }>;
type FlatAccountIndexResponse = JrpcResponse<{ items: FlatAccountInterface[] }>;
type ProfileFlatAccountCreateResponse = JrpcResponse<number, { code: number }>;
type ProfileAccountDeleteResponse = JrpcResponse<number>;
type ContractResponse = JrpcResponse<ContractResponseType>;

interface Relations {
  authenticationStore: AuthenticationStore;
  profileStore: ProfileStore;
  snackbarStore: SnackbarStore;
  userStore: UserStore;
  accountStore: AccountStore;
  preferenceStore: PreferenceStore;
  accrualStore: AccrualStore;
  operationStore: OperationStore;
  billingOrganizationStore: BillingOrganizationStore;
  smevStore: SmevStore;
  dadataStore: DadataStore;
}

class OwnProfileStore extends Store<Relations> implements Loading {
  @observable private _profile?: Profile;
  @observable private _user?: User;
  @observable private _flats: Flat[];
  @observable private _loading: boolean;
  @observable private _userDeleting: boolean;
  @observable private _billingUsers: BillingUser[];
  @observable private _selectedBillingUser: BillingUser | undefined;
  @observable private _accountDataLoading: boolean;
  @observable private _selectedFlat: Flat | undefined;
  @observable private _accountsArePreloaded: boolean;
  @observable private _accountWasAdded: boolean;
  @observable private _userPreference: UserPreference;
  @observable private _externalSupplier: boolean;
  @observable private _contract: Contract | undefined;
  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._flats = [];
    this._loading = false;
    this._userDeleting = false;
    this._accountDataLoading = false;
    this._selectedFlat = undefined;
    this._billingUsers = [];
    this._selectedBillingUser = undefined;
    this._accountsArePreloaded = false;
    this._accountWasAdded = false;
    this._userPreference = DEFAULT_USER_PREFERENCE;
    this._externalSupplier = true;
    this._contract = undefined;
  }

  @action public load = async (): Promise<void> => {
    this._loading = true;
    this._accountDataLoading = true;

    const user: User | undefined = await this.getUser();
    const profile: Profile | undefined = await this.getProfile(user);
    const preferences = await this._relations.preferenceStore.getUserPreference();
    this._userPreference = preferences;

    if (this._relations.operationStore.isAllowed(BillingMethods.USER_ACCOUNT_INDEX)) {
      await this.loadUserAccount();
    }
    if (this._relations.operationStore.isAllowed(OpencityMethods.GIS_FLAT_INDEX)) {
      this._flats = await this.loadFlat(profile);
      const { selectedFlatId } = preferences;
      const selectedFlat = this._flats.find(item => item.id === selectedFlatId);
      this.selectFlat(selectedFlat || this._flats[0]);
    }
    this._endLoading();
    this._accountDataLoading = false;
  };

  @action public selectFlat = (flat: Flat | undefined): void => {
    this._selectedFlat = flat;
    if (flat) {
      this._relations.preferenceStore.updateUserPreference({
        data: {
          ...this._userPreference,
          selectedFlatId: flat.id,
        },
      });
    }
  };

  @action public loadUserAccount = async (): Promise<void> => {
    this._loading = true;
    this._accountDataLoading = true;

    await this.getUserAccount();

    this._endLoading();
    this._accountDataLoading = false;
  };

  @action public update = async (values: Partial<Profile>): Promise<void> => {
    this._loading = true;

    let phoneNumber = '';

    if (values.phone) {
      phoneNumber = '8' + values.phone.replace(/\D/g, '').slice(1);
    }

    const profile: Profile | undefined = await this._relations.profileStore.update({
      payload: {
        ...values,
        phone: phoneNumber,
      },
    });

    this._profile = profile;

    this._endLoading();
  };

  @action public changeUserEmailConfirm = (): void => {
    if (this._user) {
      this._user = { ...this._user, isEmailConfirmed: 0 };
    }
  };

  @action public deleteUser = async (): Promise<number> => {
    this._loading = true;

    let isDeleted = 0;

    await this._relations.userStore
      .delete({ filter: { id: { $eq: this.user?.id } } })
      .then(value => (isDeleted = value))
      .finally(() => {
        this._endLoading();
        this._userDeleting = false;
      });

    return isDeleted;
  };

  @action public deleteProfile = async (): Promise<number> => {
    this._loading = true;

    const id = this.profile?.id ? this.profile?.id : undefined;

    let isDeleted = 0;

    await this._relations.profileStore
      .delete({ filter: { id: { $eq: id } } })
      .then(value => (isDeleted = value))
      .finally(this._endLoading);

    return isDeleted;
  };

  @action public cleanUp = (): void => {
    this._loading = false;
    this._user = undefined;
    this._profile = undefined;
    this._flats = [];
    // this._billingUser = undefined;
  };

  @action public addAddress = async (
    flatId: number,
    flatAccountNumber: string,
    withoutUpdate?: boolean,
  ): Promise<{ error: string }> => {
    let resultError = '';

    await this._services.opencity.requests
      .profileAccountCreate({
        params: {
          data: { flatId, profileId: this._profile?.id, personalAccountNumber: flatAccountNumber },
        },
      })
      .then(
        async ({
          data: { result, error },
        }: AxiosResponse<ProfileFlatAccountCreateResponse>): Promise<void> => {
          if (result) {
            this._loading = true;

            if (withoutUpdate) {
              this._relations.snackbarStore.setMessage('Адрес успешно добавлен', 'success');
            } else {
              this.loadFlat(this._profile)
                .then((flats: Flat[]): void => {
                  this._flats = flats;
                })
                .finally((): void => {
                  this._endLoading();

                  this._relations.snackbarStore.setMessage('Адрес успешно добавлен', 'success');
                });
            }
          }

          if (error) {
            switch (error.code) {
              case ErrorCodes.ADDRESS_ALREADY_ADDED_1: {
                resultError = 'Данный адрес уже добавлен';

                break;
              }

              case ErrorCodes.ADDRESS_ALREADY_ADDED_2: {
                resultError = 'Данный адрес уже добавлен';

                break;
              }

              case ErrorCodes.PERSONAL_ACCOUNT_NOT_FOUND_1: {
                resultError = 'Лицевой счёт не найден';

                break;
              }

              default: {
                resultError = '';
              }
            }
          }
        },
      );

    return { error: resultError };
  };

  @action public removeAddress = (flatAccountId: number): void => {
    this._loading = true;

    this._services.opencity.requests
      .profileAccountDelete({
        params: { filter: { profileId: this._profile?.id, personalAccountId: flatAccountId } },
      })
      .then(({ data: { result } }: AxiosResponse<ProfileAccountDeleteResponse>): void => {
        if (result) {
          this._flats = this._flats.filter(flat => flat.flatAccount?.id !== flatAccountId);
          this.selectFlat(this._flats[0]);
          this._relations.snackbarStore.setMessage('Адрес успешно удален', 'success');
        }
      })
      .finally(this._endLoading);
  };

  @action private getUser = async (): Promise<User | undefined> => {
    let user: User | undefined;

    if (this._relations.authenticationStore.tokenPayload) {
      await this._services.opencity.requests
        .userIndex({
          params: {
            filter: { authId: this._relations.authenticationStore.tokenPayload.sub },
          },
        })
        .then(({ data: { result } }: AxiosResponse<UserIndexResponse>): void => {
          if (result && result.items.length === 1) {
            user = new User(result.items[0]);

            this._user = user;
          }
        });
    }

    return user;
  };

  @action public selectBillingUser = async (
    user: BillingUser,
    firstSelect?: boolean,
  ): Promise<void> => {
    // if (!firstSelect && !Boolean(user?.smev)) {
    //   const rule: AxiosResponse<{
    //     result?: string;
    //     error?: any;
    //   }> = await this._services.opencity.requests.userRuleUpdate({
    //     params: {
    //       providerId: user.account?.providerId,
    //     },
    //   });

    //   if (rule.data.result) {
    //     this._relations.operationStore.load();
    //   } else {
    //     return this._relations.snackbarStore.setMessage(
    //       'Произошла ошибка, перезагрузите страницу',
    //       'error',
    //     );
    //   }
    // }

    this._selectedBillingUser = user;
    this._relations.preferenceStore.updateUserPreference({
      data: {
        ...this._userPreference,
        selectedUserId: user.id,
      },
    });

    if (!Boolean(user?.smev)) {
      this._externalSupplier = user.account ? !user.account.allowReceiveMeterReadFlag : true;
      const flat = await this.loadFlat(this._profile);
      this.selectFlat(flat[0]);
      this._relations.accrualStore.cleanUp();
    }

    if (Boolean(user?.smev)) {
      this._externalSupplier = false;
      if (user.smevData && Boolean(user.smevData.houseFias) && user.smevData.houseFias !== 'null') {
        const smevParams = {
          filter: {
            // ticketUid: {
            //   $eq: '32f883d7-d5c7-4716-85b6-b7bad6d49a04',
            // },
            houseFiasId: {
              $eq: user.smevData.houseFias || '',
            },
            accountNumber: {
              $eq: this._selectedBillingUser.number,
            },
            flatNumber: {
              // $eq: user.account?.address ? String(parseFlatNumber(user.account?.address)) : '',  //working alternative
              // $eq: dadataList.suggestions[0].address
              //   ? String(parseFlatNumber(dadataList.suggestions[0].address))
              //   : '',
              $eq: user.smevData?.flatNumber,
            },
            // houseFiasId: {
            //   $eq: '2faab5c8-5e0b-49d2-a8e4-811ec7567f0c',
            // },
            // accountNumber: {
            //   $eq: '103.3-036-1',
            // },
            // flatNumber: {
            //   $eq: '36',
            // },
            allAccounts: false,
          },
          limit: 0,
          offset: 0,
        };

        this._relations.smevStore.loadSmevAccounts(smevParams);
      } else {
        const dadataParams = {
          address: user.smevData?.houseAddress || '',
          limit: 1,
        };

        this._relations.dadataStore.loadDadataAdresses(dadataParams).then(() => {
          const dadataList = this._relations.dadataStore.dadataAdressesList;

          if (dadataList && dadataList.suggestions?.length) {
            const smevParams = {
              filter: {
                // ticketUid: {
                //   $eq: '32f883d7-d5c7-4716-85b6-b7bad6d49a04',
                // },
                houseFiasId: {
                  $eq: dadataList.suggestions[0].houseFiasId,
                },
                accountNumber: {
                  $eq: this._selectedBillingUser?.number || '',
                },
                flatNumber: {
                  // $eq: user.account?.address ? String(parseFlatNumber(user.account?.address)) : '',  //working alternative
                  // $eq: dadataList.suggestions[0].address
                  //   ? String(parseFlatNumber(dadataList.suggestions[0].address))
                  //   : '',
                  $eq:
                    user.smevData?.flatNumber ||
                    (dadataList.suggestions[0].address
                      ? String(parseFlatNumber(dadataList.suggestions[0].address))
                      : ''),
                },
                // houseFiasId: {
                //   $eq: '2faab5c8-5e0b-49d2-a8e4-811ec7567f0c',
                // },
                // accountNumber: {
                //   $eq: '103.3-036-1',
                // },
                // flatNumber: {
                //   $eq: '36',
                // },
                allAccounts: false,
              },
              limit: 0,
              offset: 0,
            };

            this._relations.smevStore.loadSmevAccounts(smevParams);
          }
        });
      }
    }
  };

  @action private getUserAccount = async (): Promise<BillingUser[]> => {
    let users: BillingUser[] = [];

    if (this._relations.authenticationStore.tokenPayload) {
      await this._services.billing.requests
        .userAccountIndex({
          params: {
            filter: { authId: this._relations.authenticationStore.tokenPayload.sub },
            limit: 0,
          },
        })
        .then(({ data: { result } }: AxiosResponse<BillingUserIndexResponse>): void => {
          if (result && result.items) {
            // result.items[0].smev = true;

            users = result.items;
          }
        });

      if (users[0]) {
        if (this._userPreference?.selectedUserId) {
          const { selectedUserId } = this._userPreference;
          const selectedUser = users.find(item => item.id === selectedUserId);
          if (selectedUser) {
            const account = await this.loadUserAccountData(
              Number(selectedUser.organizationId),
              selectedUser.number,
            );
            selectedUser.account = account;
            await this.selectBillingUser(selectedUser, true);
          }
        } else {
          const account = await this.loadUserAccountData(
            Number(users[0].organizationId),
            users[0].number,
          );
          users[0].account = account;
          await this.selectBillingUser(users[0], true);
        }
      }

      this._billingUsers = users;
    }

    return users;
  };

  @action public loadUserAccounts = async (): Promise<void> => {
    // if (this._accountsArePreloaded) return;

    for (const i in this._billingUsers) {
      if (this._billingUsers[i].smev) {
        this._billingUsers[i].account = {
          active: 1,
          address: this._billingUsers[i].smevData?.houseAddress,
          allowReceiveMeterReadFlag: false,
          currentMonthPayed: -1,
          lastPayedDate: null,
          number: this._billingUsers[i].number,
          type: 0,
          transferringPossibility: false,
        };
      } else {
        const account = await this.loadUserAccountData(
          Number(this._billingUsers[i].organizationId),
          this._billingUsers[i].number,
        );
        this._billingUsers[i].account = account;
      }
    }
    // this._accountsArePreloaded = true;
  };

  @action public loadUserAccountData = async (
    organizationId: number,
    number: string,
  ): Promise<Account> => {
    const account = await this._relations.accountStore.load({
      filter: {
        organizationId: { $eq: organizationId },
        accountNumber: { $eq: number },
      },
      withoutLoader: true,
    });

    return account[0];
  };

  @action public updateBillingUsers = async () => {
    let users: BillingUser[] = [];
    if (this._relations.authenticationStore.tokenPayload) {
      await this._services.billing.requests
        .userAccountIndex({
          params: {
            filter: {
              authId: this._relations.authenticationStore.tokenPayload.sub,
            },
            limit: 0,
          },
        })
        .then(({ data: { result } }: AxiosResponse<BillingUserIndexResponse>): void => {
          if (result && result.items) {
            users = result.items;
          }
        });

      for (const i in users) {
        const account = await this._relations.accountStore.load({
          filter: {
            organizationId: { $eq: Number(users[i].organizationId) },
            accountNumber: { $eq: users[i].number },
          },
          withoutLoader: true,
        });
        users[i].account = account[0];
      }

      this._billingUsers = users;
      this.loadUserAccounts();
    }

    return users;
  };

  @action public createBillingUser: CreateUser = async ({
    number,
    organizationId,
    organizationName,
    providerId,
    organizationInn,
  }) => {
    this._loading = true;
    const creationResult: { result: string | null; error?: string } = {
      result: null,
    };

    await this._services.billing.requests
      .userAccountCreate({
        params: {
          data: {
            ...(this._relations.authenticationStore.tokenPayload && {
              authId: this._relations.authenticationStore.tokenPayload.sub,
            }),
            organizationName,
            organizationId,
            organizationInn,
            number,
            providerId,
          },
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<UserCreateResponse>) => {
        if (result) {
          creationResult.result = result.id;
        }

        if (error && error.code === ErrorCodes.PERSONAL_ACCOUNT_NOT_FOUND_2) {
          creationResult.error = 'Лицевой счёт не найден';
        }

        if (error && error.code === ErrorCodes.PERSONAL_ACCOUNT_HAS_ALREADY_BEEN_ADDED) {
          creationResult.error = 'Данный лицевой счет уже добавлен в личный кабинет';
        }

        if (error && error.code === ErrorCodes.ANY_OTHER_ERROR) {
          creationResult.error = ' ';
          //@ts-ignore
          this._relations.snackbarStore.setMessage(error.data?.message, 'error');
        }
      })
      .finally(this._endLoading);

    return creationResult;
  };

  @action public updateBillingUser: UpdateProps = async params => {
    this._loading = true;
    let updationResult = false;

    if (this._relations.authenticationStore.tokenPayload) {
      const { accountNumber, sendToAddress, sendToEmail } = params;
      await this._services.billing.requests
        .userAccountUpdate({
          params: {
            filter: {
              authId: this._relations.authenticationStore.tokenPayload.sub,
              number: accountNumber,
            },
            data: {
              sendToAddress: sendToAddress,
              sendToEmail: sendToEmail,
            },
          },
        })
        .then(async ({ data: { result } }: AxiosResponse<UserUpdateResponse>) => {
          if (result) {
            updationResult = true;

            this._billingUsers = this._billingUsers.map(user => {
              const {
                accountNumber,
                sendToAddress = user.sendToAddress,
                sendToEmail = user.sendToEmail,
              } = params;

              if (user.number === accountNumber) {
                return {
                  ...user,
                  sendToAddress,
                  sendToEmail,
                };
              }
              return user;
            });

            if (this._selectedBillingUser?.number === accountNumber) {
              await this.selectBillingUser(
                {
                  ...this._selectedBillingUser,
                  sendToAddress,
                  sendToEmail,
                },
                true,
              );
            }
          }
        })
        .finally(this._endLoading);
    }

    return updationResult;
  };

  @action public deleteBillingUser: DeleteProps = async params => {
    this._loading = true;
    let deleteResponse = false;

    if (this._relations.authenticationStore.tokenPayload && params && params.id) {
      const { number, id, organizationId, organizationName, providerId } = params;

      await this._services.billing.requests
        .userAccountDelete({
          params: {
            filter: {
              authId: this._relations.authenticationStore.tokenPayload.sub,
              number,
              id: Number(id),
              organizationId,
              organizationName,
              providerId,
            },
          },
        })
        .then(async ({ data: { result, error } }: AxiosResponse<UserDeleteResponse>) => {
          if (result) {
            deleteResponse = true;

            this._billingUsers = this._billingUsers.filter(user => {
              if (user.number !== number || user.id !== id) {
                return user;
              }
            });

            if (
              this._selectedBillingUser?.number === number &&
              this._selectedBillingUser?.id === id &&
              this._billingUsers.length
            ) {
              await this.selectBillingUser(this._billingUsers[0]);
            }

            this._relations.snackbarStore.setMessage('Лицевой счёт успешно удалён!', 'success');
          }

          if (error && error.message) {
            this._relations.snackbarStore.setMessage('Не удалось удалить лицевой счёт', 'error');
          }
        })
        .finally(this._endLoading);
    }

    return deleteResponse;
  };

  @action private getProfile = async (user: User | undefined): Promise<Profile | undefined> => {
    let profile: Profile | undefined;

    if (user) {
      await this._services.opencity.requests
        .profileIndex({
          params: {
            filter: { id: user.profileId },
          },
        })
        .then(({ data: { result } }: AxiosResponse<ProfileIndexResponse>): void => {
          if (result && result.items.length === 1) {
            profile = new Profile(result.items[0]);

            this._profile = profile;
          }
        });
    }

    return profile;
  };

  @action private loadFlat = async (profile: Profile | undefined): Promise<Flat[]> => {
    let flats: Flat[] = [];

    if (profile) {
      await this._services.opencity.requests
        .gisFlatIndex({
          params: { filter: { citizenId: { $eq: profile.id } }, limit: 0 },
        })
        .then(({ data: { result } }: AxiosResponse<GisFlatIndexResponse>): void => {
          if (result && Array.isArray(result.items)) {
            if (Array.isArray(result.items)) {
              flats = result.items.map((value: FlatInterface): Flat => new Flat(value));
            }
          }
        });

      await this._services.opencity.requests
        .flatAccountIndex({
          params: { filter: { citizenId: { $eq: profile.id } }, limit: 0 },
        })
        .then(({ data: { result } }: AxiosResponse<FlatAccountIndexResponse>): void => {
          if (result && Array.isArray(result.items) && flats) {
            flats = flats.map(
              (flat: Flat): Flat => {
                const flatAccount: FlatAccountInterface | undefined = result.items.find(
                  (value: FlatAccountInterface): boolean => value.flatId === flat.id,
                );

                if (flatAccount) {
                  return { ...flat, flatAccount: new FlatAccount(flatAccount) };
                }

                return flat;
              },
            );
          }
        });
    }
    this._flats = flats;
    return flats;
  };

  @action public setUserDeleting = (status: boolean): void => {
    this._userDeleting = status;
  };

  @action public setAddingAccountStatus = (status: boolean): void => {
    this._accountWasAdded = status;
  };

  @action public loadContracts = async (params: ContractFilterProps) => {
    this._loading = true;

    let contract: Contract | undefined = undefined;

    const user = this._selectedBillingUser;

    if (user) {
      if (user.account?.type === 1) {
        await this._services.billing.requests
          .accountContractIndex({
            params: {
              filter: params,
            },
          })
          .then(({ data: { result } }: AxiosResponse<ContractResponse>) => {
            if (result && result.items.length) {
              contract = result.items[0];

              this._contract = contract;
            }
          })
          .finally(this._endLoading);
      }
    }

    return contract;
  };

  @computed public get contract(): Contract | undefined {
    return toJS(this._contract);
  }

  @computed public get profile(): Profile | undefined {
    return toJS<Profile | undefined>(this._profile);
  }

  @computed public get user(): User | undefined {
    return toJS<User | undefined>(this._user);
  }

  @computed public get billingUsers(): BillingUser[] {
    return toJS<BillingUser[]>(this._billingUsers);
  }

  @computed public get flats(): Flat[] {
    return toJS<Flat[]>(this._flats);
  }

  @computed public get houseIds(): number[] {
    return this._flats
      .map(item => item.houseId)
      .filter(item => typeof item === 'number') as number[];
  }

  @computed public get selectedFlat(): Flat | undefined {
    return toJS(this._selectedFlat);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get userDeleting(): boolean {
    return this._userDeleting;
  }

  @computed public get accountDataLoading(): boolean {
    return this._accountDataLoading;
  }

  @computed public get selectedBillingUser(): BillingUser | undefined {
    return toJS(this._selectedBillingUser);
  }

  @computed public get externalSupplier(): boolean {
    return this._externalSupplier;
  }

  @computed public get accountWasAdded(): boolean {
    return this._accountWasAdded;
  }
}

export default OwnProfileStore;
