import { action, observable, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import Services from '@services/index';
import { ErrorCodes, JrpcResponse } from '@httpClient/jrpc';
import Store from './Store';
import { endLoading, Loading } from '@stores/interfaces/Loading';
import {
  LoadPayment,
  Payment,
  PaymentFilterProps,
  PaymentInterface,
  BankNamesEnum,
} from '@entities/Billing/Payment';
import {
  LoadPaymentReceipt,
  PaymentReceipt,
  PaymentReceiptInterface,
  // PaymentReceiptFilterProps,
} from '@entities/Billing/PaymentReceipt';
import { LoadCards, Card } from '@entities/Billing/Card';
import AuthenticationStore from './AuthenticationStore';
import SnackbarStore from './SnackbarStore';
import { PaymentStatus, PaymentStatusInterface } from '@entities/Billing/PaymentStatus';

interface Relations {
  authenticationStore: AuthenticationStore;
  snackbarStore: SnackbarStore;
}

type PaymentGetLinkResponse = JrpcResponse<{ id: number; link: string }>;
type PaymentGetQrCodeLink = JrpcResponse<{ link: string }, { message: string }>;
type PaymentGetSbp = JrpcResponse<{ link: string; qrLink: string }>;
type PayByCardResponse = JrpcResponse<{ paymentId: string; url3DS: string | null }, string>;
type PaymentIndexResponse = JrpcResponse<{ items: PaymentInterface[]; total: number }>;
type PaymentDetachResponse = JrpcResponse<boolean>;

type PaymentReceiptIndexResponse = JrpcResponse<{
  items: PaymentReceiptInterface[];
  total: number;
}>;
type CardIndexResponse = JrpcResponse<{
  items: Card[];
  total: number;
}>;

type AttachResponse = JrpcResponse<{
  id: string;
  link: string;
}>;

export type Indication = {
  serviceName: string;
  counterSerialNumber: string;
  value: number | null;
};

type PaymentStatusResponse = JrpcResponse<PaymentStatusInterface, { data: string }>;

class PaymentStore extends Store<Relations> implements Loading {
  @observable private _loading: boolean;
  @observable private _payments: Payment[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _filter: PaymentFilterProps;
  @observable private _total: number;
  @observable private _isQiwi: boolean | null;
  @observable private _sbpLoading: boolean;
  @observable private _sbpError: boolean;
  @observable private _sbpLink: string;
  @observable private _sbpQrLink: string;

  @action private _endLoading = endLoading(50).bind(this);

  constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._isQiwi = null;
    this._loading = false;
    this._payments = [];
    this._limit = 0;
    this._offset = 0;
    this._filter = {};
    this._total = 0;
    this._sbpLoading = false;
    this._sbpError = false;
    this._sbpLink = '';
    this._sbpQrLink = '';
  }

  @computed public get isQiwi(): boolean | null {
    return this._isQiwi;
  }

  @computed public get sbpLoading(): boolean {
    return this._sbpLoading;
  }

  @computed public get sbpError(): boolean {
    return this._sbpError;
  }

  @computed public get sbpLink(): string {
    return this._sbpLink;
  }

  @computed public get sbpQrLink(): string {
    return this._sbpQrLink;
  }

  @action public getLastPayments = async (
    providerId: number,
    accountNumber: string,
  ): Promise<void> => {
    if (typeof this._isQiwi !== 'boolean') {
      await this._services.billing.requests
        .paymentIndex({
          params: {
            filter: {
              providerId,
              accountNumber,
              startDate: Number(
                moment()
                  .startOf('year')
                  .subtract(1, 'year')
                  .format('X'),
              ),
              endDate: Number(moment().format('X')),
            },
          },
        })
        .then(({ data: { result } }: AxiosResponse<PaymentIndexResponse>) => {
          if (result) {
            // если нет платежей вообще, ставим значение в true и не показываем модалку
            if (result.items.length < 3) {
              this._isQiwi = true;
            }

            if (result.items.length >= 3) {
              // вытаскиваем три последних платежа
              const res = result.items.slice(0, 3);

              const firstPaymentAccName = res[0].acceptorName || null;
              const secondPaymentAccName = res[1].acceptorName || null;
              const thirdPaymentAccName = res[2].acceptorName || null;

              const isFirstPaymentToUs =
                firstPaymentAccName?.toLowerCase().includes(BankNamesEnum.qiwi) ||
                firstPaymentAccName?.toLowerCase().includes(BankNamesEnum.tinkoff) ||
                firstPaymentAccName?.toLowerCase().includes(BankNamesEnum.premium);

              const isSecondPaymentToUs =
                secondPaymentAccName?.toLowerCase().includes(BankNamesEnum.qiwi) ||
                secondPaymentAccName?.toLowerCase().includes(BankNamesEnum.tinkoff) ||
                secondPaymentAccName?.toLowerCase().includes(BankNamesEnum.premium);

              const isThirdPaymentToUs =
                thirdPaymentAccName?.toLowerCase().includes(BankNamesEnum.qiwi) ||
                thirdPaymentAccName?.toLowerCase().includes(BankNamesEnum.tinkoff) ||
                thirdPaymentAccName?.toLowerCase().includes(BankNamesEnum.premium);

              if (!isFirstPaymentToUs && isSecondPaymentToUs) {
                this._isQiwi = false;
              } else if (!isFirstPaymentToUs && !isSecondPaymentToUs && isThirdPaymentToUs) {
                this._isQiwi = false;
              } else {
                this._isQiwi = true;
              }
            }
          }
        });
    }
  };

  @action public createQrCode = async (params: {
    providerId: number;
    accountNumber: string;
  }): Promise<{ link: string; error?: string }> => {
    let link = '';
    let error: string | undefined;

    await this._services.billing.requests
      .qrCodeCreate({ params: { data: { ...params } } })
      .then(({ data: { result, error: errorData } }: AxiosResponse<PaymentGetQrCodeLink>) => {
        if (result) {
          link = result.link;
        }

        if (errorData && errorData.data) {
          error = errorData.data.message;
        }
      });

    return { link, error };
  };

  @action public getSbpPayment = async (params: {
    emailForFiscalReceipt: string;
    paymentSource?: number;
    accountNumber: string;
    providerId: number;
    merchantGroupId: string;
    amount: number;
    date: number;
  }): Promise<{ link: string; qrLink: string }> => {
    this._sbpError = false;
    this._sbpLoading = true;

    let link = '';
    let qrLink = '';

    await this._services.billing.requests
      .paymentSbpGet({ params: { ...params } })
      .then(({ data: { result, error: errorData, name } }: AxiosResponse<PaymentGetSbp>) => {
        if (result) {
          link = result.link;
          qrLink = result.qrLink;

          this._sbpLink = result.link;
          this._sbpQrLink = result.qrLink;
        }

        if (name && name === 'Exception') {
          this._sbpError = true;
        }

        if (errorData && errorData.data) {
          this._sbpError = true;
        }
      })
      .catch(() => {
        this._sbpError = true;
      })
      .finally(() => {
        this._sbpLoading = false;
      });

    return { link, qrLink };
  };

  @action public cleanUpSbp = (): void => {
    this._sbpError = false;
    this._sbpLoading = false;
    this._sbpLink = '';
    this._sbpQrLink = '';
  };

  @action public sendQrOnEmail = async (params: {
    accountNumber: string;
    providerId: number;
    email: string;
  }): Promise<{ error?: string }> => {
    let error: string | undefined;

    await this._services.billing.requests
      .qrCodeSend({ params })
      .then(({ data }: AxiosResponse<PaymentGetQrCodeLink>) => {
        if (data.error || !data.result) {
          error = 'error';
        }
      });

    return { error };
  };

  @action public getLink = async (params: {
    providerId: number;
    accountNumber: string;
    merchantGroupId: string;
    amount: number;
    date: number;
    distribution?: Array<{ merchantId: string; amount: number }>;
    indications?: Indication[];
    bindPaymentToken?: boolean;
    paymentSource?: number;
    emailForFiscalReceipt?: string;
  }): Promise<{ value?: string; error?: string }> => {
    let link = '';

    let errorMessage: string | undefined = '';

    await this._services.billing.requests
      .paymentGetLink({
        params,
      })
      .then(({ data: { result, error } }: AxiosResponse<PaymentGetLinkResponse>) => {
        if (result) {
          link = result.link;
        }

        if (error) {
          errorMessage = error.data as string;
        }
      });

    return { value: link, error: errorMessage };
  };

  @action public checkPaymentStatus = async (params: {
    providerId: number;
    accountNumber: string;
    paymentId: string;
  }): Promise<null | PaymentStatus> => {
    let paymentStatus = null;

    await this._services.billing.requests
      .paymentStatus({ params })
      .then(({ data: { result, error } }: AxiosResponse<PaymentStatusResponse>) => {
        if (result) {
          paymentStatus = new PaymentStatus(result);
        }

        if (error?.data) {
          if (error?.code && error.code === ErrorCodes.WAITING) {
            paymentStatus = new PaymentStatus({ paymentId: params.paymentId, status: 'waiting' });
          } else {
            this._relations.snackbarStore.setMessage(error.data.data, 'error');
          }
        }
      });

    return paymentStatus;
  };

  @action public payByCard = async (params: {
    providerId: number;
    accountNumber: string;
    merchantGroupId: string;
    amount: number;
    date: number;
    tokenId: number;
    emailForFiscalReceipt?: string;
    source?: number;
  }): Promise<null | { paymentId: string | null; url3DS: string | null }> => {
    let paymentId = null;
    let url3DS = null;

    await this._services.billing.requests
      .paymentCardPay({ params })
      .then(({ data: { result, error } }: AxiosResponse<PayByCardResponse>) => {
        if (result) {
          paymentId = result.paymentId;
          url3DS = result.url3DS;
        }

        if (error?.data) {
          this._relations.snackbarStore.setMessage(error.data, 'error');
        }
      });

    return { paymentId, url3DS };
  };

  @action public attachCard = async (params: {
    merchantGroupId: string;
  }): Promise<{ value: string }> => {
    let link = '';
    await this._services.billing.requests
      .paymentCardAttach({ params })
      .then(({ data: { result } }: AxiosResponse<AttachResponse>) => {
        if (result) {
          link = result.link;
        } else {
          this._relations.snackbarStore.setMessage(
            'Возникла ошибка при получении ссылки на эквайринг',
            'error',
          );
        }
      });

    return { value: link };
  };

  @action public detachCard = async (params: {
    tokenId: number;
    noMessage?: boolean;
  }): Promise<boolean> => {
    let detachResult = false;

    await this._services.billing.requests
      .paymentCardDetach({ params: { tokenId: params.tokenId } })
      .then(({ data: { result } }: AxiosResponse<PaymentDetachResponse>) => {
        if (result) {
          detachResult = result;
          !params.noMessage && this._relations.snackbarStore.setMessage('Карта удалена', 'success');
        } else {
          !params.noMessage &&
            this._relations.snackbarStore.setMessage('Возникла ошибка при удалении карты', 'error');
        }
      });

    return detachResult;
  };

  @action public loadCards: LoadCards = async params => {
    let cards: Card[] = [];

    await this._services.billing.requests
      .paymentCardIndex({
        params: {
          filter: params
            ? {
                ...params.filter,
                ...(this._relations.authenticationStore.tokenPayload && {
                  authId: this._relations.authenticationStore.tokenPayload.sub,
                }),
              }
            : {},
          limit: params?.limit || 0,
          offset: params?.offset || 0,
        },
      })
      .then(({ data: { result } }: AxiosResponse<CardIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          cards = result.items.map(value => new Card(value));
        }
      });

    return cards;
  };

  @action public getPaymentReceipt: LoadPaymentReceipt = async params => {
    let receipts: PaymentReceipt[] = [];
    // this._loading = true;

    await this._services.billing.requests
      .paymentReceiptIndex({
        params: {
          filter: params ? params.filter : {},
          limit: params?.limit || 10,
          offset: params?.offset || 0,
        },
      })
      .then(({ data: { result } }: AxiosResponse<PaymentReceiptIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          receipts = result.items.map(value => new PaymentReceipt(value));
        }
      });
    // .finally(this._endLoading);

    return receipts;
  };

  @action public load: LoadPayment = async params => {
    let payments: Payment[] = [];
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .paymentIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<PaymentIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          payments = result.items.map(value => new Payment(value));

          this._payments = payments;
        }

        if (typeof result?.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return payments;
  };

  @action public cleanUp = (): void => {
    this._loading = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default PaymentStore;
