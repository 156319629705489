import React, { FC, ReactElement, useState, useRef, LegacyRef, KeyboardEvent } from 'react';
import { useFormik } from 'formik';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReCAPTCHA from 'react-google-recaptcha';

import NameApplicantField from '@components/Fields/NameApplicantField';
import EmailField from '@components/Fields/EmailField';
import DescriptionField from '@components/Fields/DescriptionField';
import { useFeedbackStore, useSignStore } from './hooks';
import { FormSchema } from './FormSchema';
import config from '@core/constants/config';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  fromPublic?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      width: theme.spacing(32),
    },
    emailField: {
      marginBottom: '15px',
    },
    recaptchaText: {
      color: 'rgba(68, 84, 107, 0.5)',
      fontSize: 13,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),

      textAlign: 'center',
      '& > a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  }),
);

const FORM_ID = 'feedback_dialog__form';

const FeedbackDialog: FC<FeedbackDialogProps> = ({ open, onClose }) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const classes = useStyles();
  const { create } = useFeedbackStore();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { captchaCheck } = useSignStore();
  const [isRobot, setIsRobot] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      nameApplicant: '',
      email: '',
      address: '',
      title: '',
      text: '',
      personalAccount: '',
    },
    validationSchema: FormSchema,
    onSubmit: async values => {
      if (recaptchaRef.current) {
        const response = await recaptchaRef.current.executeAsync();
        const isHuman = await captchaCheck({ response: String(response) }, true);

        if (!isHuman) {
          setIsRobot(true);
          return;
        }

        await recaptchaRef.current.reset();
      }

      create(
        {
          data: {
            title: values.title,
            body: `<b>Описание</b> <br>: ${values.text}
            ${values.address ? `<br>Адрес: ${values.address}` : ''}
            <br>Лицевой счет: ${values.personalAccount}`,
            fio: values.nameApplicant,
            email: values.email,
          },
        },
        { notification: true },
      );

      resetForm();

      onClose();
    },
  });

  const handleClose = (): void => {
    onClose();

    resetForm();
  };

  const titleElement: ReactElement = <DialogTitle>Обратная связь</DialogTitle>;

  const RecaptchaInfo: ReactElement = (
    <div className={classes.recaptchaText}>
      Этот сайт защищен reCAPTCHA и применяются&nbsp;
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Политика конфиденциальности
      </a>
      &nbsp;и&nbsp;
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        Условия использования
      </a>
      &nbsp;Google.
    </div>
  );

  const content: ReactElement = (
    <DialogContent>
      <form id={FORM_ID} noValidate={true} onSubmit={handleSubmit}>
        <Grid container={true} direction="column">
          <Grid item={true} container={true}>
            <NameApplicantField
              id={`${FORM_ID}__name-applicant`}
              label="Ваше имя"
              required={true}
              value={values.nameApplicant}
              placeholder=" "
              helperText=" "
              error={errors.nameApplicant}
              touched={touched.nameApplicant}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item={true} container={true}>
            <EmailField
              id={`${FORM_ID}__email`}
              className={classes.emailField}
              required={true}
              helperText="На этот адрес будет отправлен ответ"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__title`}
              fullWidth={true}
              label="Заголовок"
              required={true}
              name="title"
              value={values.title}
              error={Boolean(errors.title && touched.title)}
              helperText={Boolean(errors.title && touched.title) ? errors.title : ' '}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item={true} container={true}>
            <DescriptionField
              id={`${FORM_ID}__description`}
              label="Причина"
              helperText=" "
              required={true}
              value={values.text}
              error={errors.text}
              touched={touched.text}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__address`}
              fullWidth={true}
              label="Адрес"
              name="address"
              value={values.address}
              error={Boolean(errors.address && touched.address)}
              helperText={Boolean(errors.address && touched.address) ? errors.address : ' '}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__personalAccount`}
              fullWidth={true}
              label="Лицевой счет"
              name="personalAccount"
              value={values.personalAccount}
              error={Boolean(errors.personalAccount && touched.personalAccount)}
              helperText={
                Boolean(errors.personalAccount && touched.personalAccount)
                  ? errors.personalAccount
                  : ' '
              }
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
              inputProps={{ maxLength: 20, type: 'text' }}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );

  const actions: ReactElement = (
    <DialogActions>
      <Button
        className={classes.button}
        id={`${FORM_ID}__cancel`}
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleClose}
      >
        Отмена
      </Button>
      <Button
        className={classes.button}
        id={`${FORM_ID}__close`}
        type="submit"
        form={FORM_ID}
        color="primary"
        variant="contained"
        size="small"
        disabled={isRobot}
      >
        Отправить
      </Button>
    </DialogActions>
  );

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" fullScreen={matches} onClose={handleClose}>
      {titleElement}
      {content}
      {actions}
      {RecaptchaInfo}
      {config.recaptchaKey && (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.recaptchaKey} />
      )}
    </Dialog>
  );
};

export default FeedbackDialog;
